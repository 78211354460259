export const faqDetails = [
      {
        question: "What is the vision of CoinThrough?",
        answer: "CoinThrough is a dedicated platform that focuses on technological innovation and the most recent advancements. Our belief at CoinThrough is centered around the exploration of cutting-edge developments that significantly influence our world and bring about revolutionary changes in various industries."
      },
      {
        question: "What are the current services provided by CoinThrough?",
        answer: "CoinThrough presently publishes blogs regarding the latest technology and trends. Moving forward, we have plans to introduce pilot programs in the tech industry."
      }
    ]
