import './ContactUs.css'
import {useRef} from "react";
import emailjs from '@emailjs/browser';

function ContactUs({activeTab}) {
  const form = useRef()

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_s0m94ww', 'template_4vtrqen', form.current, '0cvn3sEd9rLn0NcLt')
    .then((result) => {
      console.log(result.text);
    }, (error) => {
      console.log(error.text);
    });
    e.target.reset();
  };

  return (
      <div className={activeTab === "contactUs" ? "active-content": "content"}>
        <section>
          <div className="container">
            <h2>Contact Us</h2>
            <form ref={form} onSubmit={sendEmail} className="contactUs-form">
              <input type="text" placeholder="Full Name" name="user_name" size="25" required/>
              <input type="email" placeholder="Email" name="user_email" size="25" required/>
              <input type="text" placeholder="Subject" name="email_subject" size="25" required/>
              <input className="message" type="text" placeholder="Message" name="email_message" size="250"  required/>
              <button type="submit" className="--btn-primary" >Send Message</button>
            </form>
          </div>
        </section>
        <section>
          <div className="container">
            <h2>CoinThrough</h2>
            <ul>
              <li>Email: contact-us@cointhrough.com</li>
              <li>Address: Sector-63A, Noida, UP, India, 201301</li>
            </ul>
          </div>
        </section>
      </div>
  );
}

export default ContactUs;
