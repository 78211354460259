import './Blogs.css'

function Blogs({activeTab}) {
    return (
        <div className={activeTab === "blogs" ? "container active-content": "container content"}>
            <h1>Recent Blogs</h1>
            <ul>
                <li>
                    <a target="_blank" href="https://cointhrough.com/cartier-glasses/">Unveiling the Glamour: How Cartier Glasses Are Revamping Eyewear Fashion</a>
                </li>
            </ul>
        </div>
    );
}

export default Blogs;
