import './App.css';
import Header from "./component/header/Header";
import Footer from "./component/footer/Footer";
import Home from "./component/home/Home";
import ContactUs from "./component/contactUs/ContactUs";
import {useState} from "react";
import Faq from "./component/faq/Faq";
import Blogs from "./component/blogs/Blogs";

function App() {
  const [activeTab, setActiveTab] = useState("home");

  return (
      <div className='app'>
        <Header activeTab={activeTab} setActiveTab={setActiveTab}/>
        <Faq/>
        <Home activeTab={activeTab}/>
        <Blogs activeTab={activeTab}/>
        <ContactUs activeTab={activeTab}/>
        <Footer/>
      </div>
  );
}

export default App;
