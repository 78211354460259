import './Header.css'
import logo from "../../images/logo192.png";

function Header({activeTab, setActiveTab}) {

  return (
      <header className='header'>
          <nav className='nav'>
              <img src={logo} alt="logo" className='nav-logo'/>
              <ul className='nav-items'>
                  <li><div onClick={() => setActiveTab("home")} className={activeTab === "home" ? "active-tabs": "tabs"}>Home</div></li>
                  <li><div onClick={() => setActiveTab("explore")} className={activeTab === "explore" ? "active-tabs": "tabs"}>Explore</div></li>
                  <li><div onClick={() => setActiveTab("blogs")} className={activeTab === "blogs" ? "active-tabs": "tabs"}>Blogs</div></li>
                  <li><div onClick={() => setActiveTab("contactUs")} className={activeTab === "contactUs" ? "active-tabs": "tabs"}>ContactUs</div></li>
              </ul>
          </nav>
      </header>
  );
}

export default Header;
