import {faqDetails} from "./FaqDetails";
import {useState} from "react";
import './Faq.css'

function Faq() {
  const [selectedFaq, setSelectedFaq] = useState(null);

  const toggle = (i) => {
    if (selectedFaq === i) {
      return setSelectedFaq(null);
    }
    setSelectedFaq(i);
  }

  return (
      <div className='accordion'>
          <h2>FAQ</h2>
          {faqDetails.map((faq, i) => (
              <div className='faq'>
                  <div className='question' onClick={() => toggle(i)}><b>{faq.question}</b></div>
                  <span>{selectedFaq === i ? '-':'+'}</span>
                  <div className={selectedFaq === i ? 'answer show':'answer'} >{faq.answer}</div>
              </div>
          ))}
      </div>
  );
}

export default Faq;
