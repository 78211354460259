import './Home.css'

function Home({activeTab}) {
    return (
        <div className={activeTab === "home" ? "container active-content": "container content"}>
            <h1>Welcome to CoinThrough</h1>
            <p>A platform dedicated to innovation and the latest advancements in technology. At CoinThrough, we believe in exploring the cutting-edge developments that shape our world and revolutionize industries.</p>
            <p>Our mission is to provide you with insightful and engaging content through our thought-provoking blogs. We delve into a wide range of topics, including blockchain, artificial intelligence, cryptocurrency, Internet of Things (IoT), and much more. Whether you are a tech enthusiast, a professional in the field, or simply curious about the future, our blog articles will keep you informed and inspired.</p>
            <p>Join us on a journey of discovery as we uncover groundbreaking innovations, discuss their potential impact, and examine the challenges and opportunities they present. Our team of passionate writers and experts ensures that our content is well-researched, accurate, and accessible, making complex concepts understandable for everyone.</p>
            <p>CoinThrough is not just a website; it's a community of forward-thinkers, industry leaders, and enthusiasts who are eager to explore the limitless possibilities of tomorrow. We invite you to engage with our content, share your thoughts, and be part of the conversation that shapes the future.</p>
            <p>Stay tuned for our regularly updated blog posts, where we explore the frontiers of technology and bring you the latest insights on the innovations that are shaping our world. CoinThrough is your gateway to the future of technology. Join us today and embark on a journey of innovation and knowledge!</p>
        </div>
    );
}

export default Home;
