import './Footer.css'

function Footer() {
  return (
      <footer className='footer'>
        <small>©2023 CoinThrough. All rights reserved.</small>
      </footer>
  );
}

export default Footer;
